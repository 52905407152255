.header-area {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    padding: 16px 0;
    position: fixed;
    width: 100%;
    z-index: 11;
    transition: all .3s linear;
}
.topnav{
    padding: 10px 0;
}