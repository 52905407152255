.banner-wrapper {
    background-image: url('../../img/banner-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 88px;
    padding-top: 70px;
    @media (max-width: 991.98px){
        padding-bottom: 32px;
        background: none;
    }

}

.banner-area {
    padding: 88px 0;
    @media (max-width: 991.98px){
        padding: 32px 0;
        background-image: url('../../img/phone-banner-bg.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    h1 {
        font-weight: 700;
        font-size: 64px;
        line-height: 80px;
        color: #0A1172;
        @media (max-width: 991.98px){
            font-size: 40px;
            line-height: 56px;
        }
    }

    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 38px;
        color: #5C6077;
        margin-bottom: 0;
        padding: 13px 0 28px;
        @media (max-width: 991.98px){
            font-size: 16px;
            line-height: 32px;
            padding: 8px 0 20px;
        }

    } 

    .banner-img{
        mix-blend-mode: multiply;
    }
}