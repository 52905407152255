// Google Fonts
// font-family: 'Roboto', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
// font-family: 'Roboto Slab', serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500;600;700&display=swap');
// font-family: 'Inter', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

// Font Awesome
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");

// Light Gallery
// @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css');
// @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css');
// @import url('https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css');


// Quick code
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: none;
	box-shadow: none;
	text-decoration: none;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.2;
	font-family: 'Roboto Slab'; 
}
div#root {
	overflow: hidden;
}
ol,
ul {
	list-style: none;
}
 
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, p{
  margin-bottom: 0 !important;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
p {
	font-weight: 400;
	font-size: 16px;
	line-height: 1.5;
	font-family: 'Inter';
}
.bg-c {
	background: #f2f2f2;
}
.btn-lg {
  padding: 12px 16px;
  background: #5120A6;
  border-radius: 12px; 
  min-width: 139px;  
  font-weight: 700;
  font-size: 16px;
  line-height: 24px; 
  color: #FFFFFF;
	text-decoration: none;
	display: inline-flex; 
	align-items: center;
	justify-content: center; 
	gap: 15px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	transition: all 0.3s linear;
	cursor: pointer;
	text-transform: capitalize;
  border: 0;
	&:after {
		position: absolute;
		content: "";
		top: 100%;
		width: 100%;
		height: 100%;
		left: 0;
		background-color: #FF8181;
		z-index: -1;
		transition: all 0.3s linear;
	}
	&:hover {
		color: #fff;
		&:after {
			top: 0;
		}
	}
	@media (max-width: 991.98px){
    width: 100%;
  }
}

.btn-md {
	padding: 12px 16px;
  background: #FFF5F5; 
  border: 1px solid #5120A6;
  border-radius: 12px; 
  min-width: 139px;  
  font-weight: 700;
  font-size: 16px;
  line-height: 24px; 
  color: #FF8181;
  padding: 12px 16px;
	text-decoration: none;
	display: inline-flex; 
	align-items: center;
	justify-content: center; 
	gap: 15px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	transition: all 0.3s linear;
	cursor: pointer;
	text-transform: capitalize; 
	&:after {
		position: absolute;
		content: "";
		top: 100%;
		width: 100%;
		height: 100%;
		left: 0;
		background-color: #FF8181;
		z-index: -1;
		transition: all 0.3s linear;
	}
	&:hover {
		color: #fff;
		&:after {
			top: 0;
		}
	}
	@media (max-width: 991.98px){
    width: 100%;
  }
}
.read-more{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%; 
  color: #FFFFFF;
  position: relative;
  text-decoration: none;
  &::after{
    content: "\f105";
    font-family: "Font Awesome 6 free";
    font-weight: 900;
    margin-left: 10px;
  }
  &:hover{
	color: #ad1aaf;
  }

}
.btn-md-primary {
	color: #ad1aaf;
	border-color: #ad1aaf;
	&::before,
	&::after {
		background-color: #ad1aaf;
	}
}
@media (max-width: 991.98px){
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.btn-close {
	// filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(167deg) brightness(105%) contrast(102%);
	float: right;
}

.text-xs-center {
	@media (max-width: 767.98px) {
		text-align: center;
	}
}
@media (max-width: 991.98px) {
	.row-gap {
		row-gap: 30px;
	}
}

.navbar-toggler {
	outline: none !important;
	box-shadow: none !important;
}

.responsive {
	width: 100%;
} 
.h2.section-title {
	font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 52px; 
  color: #18214D;
  padding-bottom: 32px;
  @media (max-width: 991.98px){
    font-size: 32px;
    line-height: 44px;
    padding-bottom: 16px;
  }
  span{
    position: relative;
    z-index: 1;
    &::after{
      content: "";
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      height: 8px;
      background: #FF8181;
      opacity: 0.5;
      z-index: -1;
    }
  }
} 
.text-primary {
	color: #ad1aaf !important;
}


// .modal .btn-close {
//     position: absolute;
//     top: -27px;
//     right: 0;
//     opacity: 1;
// }
.nav-link {
	cursor: pointer;
}
.pt-100 {
	padding-top: 100px;
}
.pb-100 {
	padding-bottom: 100px;
}
.py-100 {
	padding: 100px 0;
}

.pt-110 {
	padding-top: 110px;
}
.pb-110 {
	padding-bottom: 110px;
}
.py-110 {
	padding: 110px 0;
}

.pt-120 {
	padding-top: 120px;
}
.pb-120 {
	padding-bottom: 120px;
}
.py-120 {
	padding: 120px 0;
}

.pt-130 {
	padding-top: 130px;
}
.pb-130 {
	padding-bottom: 130px;
}
.py-130 {
	padding: 130px 0;
}

@media (max-width: 991.98px) {
	.pt-100 {
		padding-top: 80px;
	}
	.pb-100 {
		padding-bottom: 80px;
	}
	.py-100 {
		padding: 80px 0;
	}

	.pt-110 {
		padding-top: 85px;
	}
	.pb-110 {
		padding-bottom: 85px;
	}
	.py-110 {
		padding: 85px 0;
	}

	.pt-120 {
		padding-top: 85px;
	}
	.pb-120 {
		padding-bottom: 85px;
	}
	.py-120 {
		padding: 85px 0;
	}

	.pt-130 {
		padding-top: 90px;
	}
	.pb-130 {
		padding-bottom: 90px;
	}
	.py-130 {
		padding: 90px 0;
	}
}
