.disclaimer-area {
    text-align: center;
    padding: 88px 0;
    @media (max-width: 991.98px){
        padding: 48px 0;
    }

    .h6 {
        font-family: 'Roboto Slab';
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: #18214D;

    }

    p {
        max-width: 774px;
        margin: 0 auto;
        font-family: 'Roboto Slab';
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #5C6077;

        @media (max-width: 991.98px) {
            text-align: left;
            font-size: 14px;
            line-height: 28px;
        }
    }
}