.choose-area {
    padding: 125px 0;
    background-image: url('../../img/choose-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 991.98px) {
        padding: 0 0 32px;
    }
}

.choose-text {
    margin-top: 32px;
    margin-left: 25px;
    @media (max-width: 991.98px){
        margin-left: 0;
    }

    p {
        font-family: 'Roboto Slab';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 38px;
        color: #5C6077;
        max-width: 530px;

        @media (max-width: 991.98px) {
            font-size: 16px;
            line-height: 28px;
        }
    }

    .section-title span::after {
        bottom: 10px;
        left: 0;
        width: 93%;
    }
}