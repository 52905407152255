.about-area {
    padding: 120px 0;
    background-image: url('../../img/choose-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 991.98px) {
        padding: 56px 0;
    }

    .about-text {
        margin-top: 32px;
        p {
            max-width: 575px;
            font-weight: 400;
            font-size: 20px;
            line-height: 38px;
            color: #5C6077;
            font-family: 'Roboto Slab';

            @media (max-width: 991.98px) {
                font-size: 16px;
                line-height: 28px;
            }
        }
    }
}