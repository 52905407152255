.working-area {
    background: #F6F9FE;
    padding: 71px 0 110px;
    @media (max-width: 991.98px){
        padding: 56px 0 56px;
    }

    .working-items {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;
        @media (max-width: 991.98px){
            grid-template-columns: repeat(4, 1fr);
            gap: 14px;
        }
        @media (max-width: 575.98px){
            grid-template-columns: repeat(2, 1fr);
        }

        .working-logo {
            background: #FFFFFF; 
            height: 80px;
            display: grid;
            align-items: center;
            justify-content: center;
            transition: .3s linear;
            &:hover{
                transform: scale(1.1);
            }
        }
    }
    .slick-dots{
        bottom: -40px;
    }
    
    .slick-dots li button:before{
        width: 12px;
        height: 12px;
        font-size: 12px;
        opacity: .15;
        color: #679cff;

    }
    .slick-dots li.slick-active button:before {
        opacity: .75;
        color: #377DFF;
    }
}