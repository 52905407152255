.saying-area {
    position: relative;
    z-index: 1;
    padding: 88px 0 120px;

    @media (max-width: 991.98px) {
        padding: 30px 0 48px;
    }

    // &::before {
    //     content: '';
    //     position: absolute;
    //     width: 100%;
    //     height: 500px;
    //     top: 0;
    //     left: 0;
    //     background: #4732BA;
    //     z-index: -1;
    // }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 500px;
        top: 0;
        left: 0;
        z-index: -1;
        background-image: url('../../img/saying.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: 991.98px) {
            height: 275px;
        }
    }

    @media (max-width: 991.98px) {
        .h2.section-title {
            max-width: 201px;
            margin: 0 auto;
        }
    }


}

.saying-item {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(212, 217, 227, 0.72);
    border-radius: 20px;
    padding: 32px;
    @media (max-width: 991.98px){
        padding: 20px;
    }

    p {
        font-family: 'Roboto Slab';
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        color: #5C6077;

        @media (max-width: 991.98px) {
            font-size: 16px;
            line-height: 28px;
        }

    }

    .client-user {
        background: #4732BA;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 20px;
        margin-top: 28px;
        @media (max-width: 991.98px){
            padding: 14px;
        }

        .stet {
            margin-left: auto;
            display: flex;
            gap: 5px;

            svg {
                color: #FFAC4A;
                font-size: 13px;
            }
        }

        .text {
            .h6 {
                font-family: 'Roboto Slab';

                font-weight: 700;
                font-size: 16px;
                line-height: 24px;

                color: #FFFFFF;
                
            }

            p {
                font-family: 'Roboto Slab';
                font-size: 16px;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.8);

            }
        }
    }
}

.saying-content {
    display: grid;
    gap: 25px;
}

.saying-slid-area{
    .slick-slider {
        margin:0 -15px;
    }
    .slick-slide {
        padding:10px; 
    }
}