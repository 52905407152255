.call-to-action {
    background: rgba(255, 129, 129, 0.04);
    padding: 88px 0;

    @media (max-width: 991.98px) {
        padding: 56px 0;
    }

    .h2.section-title {
        padding-bottom: 0;

        b {
            color: #4732BA;

        }
        @media (max-width: 991.98px) {
            max-width: 278px;
            margin: 0 auto;
        }
    }

    .btn-md {
        margin: 0 auto;
        @media (max-width: 991.98px){
            background: #FF8181;
            color: #FFFFFF;
        }
    }

    p {
        font-family: 'Roboto Slab';
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #5C6077;
        padding: 10px 0 32px;

        @media (max-width: 991.98px) {
            font-size: 18px;
            line-height: 32px;
            padding: 10px 0 24px;
        }
    }
}