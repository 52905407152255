.footer-area {
    background: #F6F9FE;
    padding: 40px 0;

}

.footer-item {
    p {
        text-align: right;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 16px;
        line-height: 180%;
        color: #9FA4BC;

        @media (max-width: 991.98px) {
            text-align: center;
        }

    }
}

.social {
    display: flex;
    gap: 10px;

    a {
        width: 40px;
        height: 40px;
        background: #614ecf;
        border: 5px solid #4732BA;
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        transition: all .3s linear;

        &:hover {
            background: #4732BA;
        }

    }
}

.footer-phone {
    background: #F6F9FE;
    padding: 0 20px;

    .logo {
        padding: 18px 0;
        text-align: center;
        background: rgba(255, 255, 255, 0.829);
    }

    .social {
        padding: 24px;
        border-bottom: 1px solid #E0EBFC;
        justify-content: center;
    }

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 180%;
        padding: 24px 0;
    }
}