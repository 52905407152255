.services-area {
    @media (max-width: 991.98px) {
        padding-top: 32px;
    }
}

.services-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 56px;

    @media (max-width: 991.98px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 16px
    }

    .services-item {
        padding: 8px 0;
        border-right: 1px solid #D0D4EA;

        @media (max-width: 991.98px) {
            border-right: 0;
            border-bottom: 1px solid #D0D4EA;
            padding-bottom: 20px;
        }

        &:last-child {
            border: 0;

            @media (max-width: 991.98px) {
                padding-bottom: 0;
            }
        }

        &:nth-child(1) {
            p {
                max-width: 297px;
            }
        }

        &:nth-child(2) {
            p {
                max-width: 322px;
            }
        }

        &:nth-child(3) {
            p {
                max-width: 297px;
            }
        }

        .h6 {
            font-weight: 700;
            font-size: 20px;
            line-height: 180%;
            color: #18214D;
            font-family: 'Roboto Slab';

            @media (max-width: 767.98px) {
                font-size: 18px;
            }
        }

        P {
            font-size: 20px;
            line-height: 32px;
            color: #5C6077;
            font-family: 'Roboto Slab';

            @media (max-width: 767.98px) {
                font-size: 16px;
                line-height: 28px;
            }

        }
    }
}